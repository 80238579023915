<template>
  <div>
    <Breadcrumbs :page_title="$store.getters.event.courseName" :items="breadcrumbs" />
    <v-card class="mx-3 my-5" style="border-radius:16px;">
      <v-container>
        <v-row class="mt-5">
          <v-col cols="12" xs="12" sm="12" md="4" lg="3">
            <div>
              <v-card
                width="230"
                height="180"
                style="border: 4px dashed #C0C0C0;box-shadow: unset;
                    background: #F8F8F8 0% 0% no-repeat padding-box;"
              >
                <v-img
                  width="200"
                  height="150"
                  style="border-radius: 8px !important;"
                  class="mx-auto center mt-3"
                  :src="$store.getters.event.orgImage"
                  @mouseover="$store.getters.event.orgImage ? showEditImgTool = true : showEditImgTool = false"
                  @mouseleave="$store.getters.event.orgImage ? showEditImgTool = false : showEditImgTool = false"
                  v-if="$store.getters.event.orgImage"
                >
                  <v-card-title
                    v-if="showEditImgTool"
                    style="left: 15%; top: 15%;position: absolute;"
                  >
                    <v-list
                      class="d-flex flex-column align-start"
                      style="padding: 0;border-radius: 8px;"
                    >
                      <v-list-item class="list-item-padding">
                        <v-btn
                          color="#424242"
                          style="font-size: 14px;width: 100px"
                          text
                          class="text-capitalize"
                          @click.stop="previewimg=true"
                        >
                          <v-icon left>mdi-eye-outline</v-icon>
                          {{ $t("preview") }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card-title>
                </v-img>
                <v-img
                  v-else
                  src="@/assets/image_loader.png"
                  width="64"
                  height="52"
                  class="mx-auto center"
                  style="margin-top: 2.5rem;"
                ></v-img>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="8" lg="9">
            <v-row no-gutters>
              <v-col cols="3">
                <p style="color: #424242; font-size: 20px;font-weight: bold;">{{ $t("inchargesm") }}</p>
              </v-col>
              <v-col cols="9">
                <p style="font-size: 18px;color: #424242;">{{ $store.getters.event.teacherName }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <p style="color: #424242; font-size: 20px;font-weight: bold;">{{ $t("time") }}</p>
              </v-col>
              <v-col cols="9">
                <p style="font-size: 18px;color: #424242;">{{ courseTime }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" style="color: #424242; font-size: 20px;font-weight: bold;">
                {{
                $t("locationsm")
                }}
              </v-col>
              <v-col cols="9">
                <p style="font-size: 18px;color: #424242;">{{ $store.getters.event.courseLocation }}</p>
              </v-col>
            </v-row>
            <v-row 
              no-gutters
              v-if="(this.$store.getters.event.wordDescription) !== null && (this.$store.getters.event.wordDescription) !== ''">
              <v-col cols="3" style="color: #424242; font-size: 20px;font-weight: bold;">
                {{
                $t("description")
                }}
              </v-col>
              <v-col cols="9">
                <p
                  class="breaktext"
                  style="text-align:justify;font-size: 18px;color: #424242;"
                >{{ $store.getters.event.wordDescription }}</p>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-if="(this.$store.getters.event.link) !== null && (this.$store.getters.event.link) !== ''"
            >
              <v-col
                v-if="(this.$store.getters.event.link) !== null && (this.$store.getters.event.link) !== ''"
                cols="3"
                style="color: #424242; font-size: 20px;font-weight: bold;"
              >{{ $t("link") }}</v-col>
              <v-col
                v-if="(this.$store.getters.event.link) !== null && (this.$store.getters.event.link) !== ''"
                cols="9"
              >
                <p style="font-size: 18px;color: #424242;">{{ $store.getters.event.link }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" style="color: #424242; font-size: 20px;font-weight: bold;">
                {{
                $t("limitation")
                }}
              </v-col>
              <v-col cols="9">
                <p style="font-size: 18px;color: #424242;">
                  {{ $store.getters.event.noOfParticipants }}
                  {{ $t("participants") }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" style="color: #424242; font-size: 20px;font-weight: bold;">
                {{
                $t("enrolled")
                }}
              </v-col>
              <v-col cols="9">
                <p style="font-size: 18px;color: #424242;">{{ $store.getters.event.enrollCount }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3" style="color: #424242; font-size: 20px;font-weight: bold;">
                {{
                $t("remaining")
                }}
              </v-col>
              <v-col cols="9">
                <p style="font-size: 18px;color: #424242;">{{remainingcount}}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-progress-circular v-if="loading" indeterminate class="loading-circle" color="green"></v-progress-circular>
        <v-row>
          <v-col style="text-align: right !important;">
            <v-hover v-slot="{ hover }">
              <v-btn
                class="mr-3 text-capitalize"
                style="font-size: 16px;color: #4FB14E;background-color: rgb(79, 177, 78,0.2);;border-radius: border-radius: 8px;box-shadow: unset;"
                :style="{'color': hover ? '#ffffff' : '#4FB14E',
                          'background-color': hover ? '#4FB14E' : 'rgb(79, 177, 78,0.2)'}"
                @click="checkEventEdit()"
              >
                <v-icon class="mr-1" style="padding-bottom: 3px;">mdi-square-edit-outline</v-icon>
                {{ $t("edit") }}
              </v-btn>
            </v-hover>
            <v-btn
              color="error"
              class="mr-3 text-capitalize"
              style="font-size: 16px;color: #ffffff;background-color: rgb(255, 96, 96,0.2);;border-radius: border-radius: 8px;box-shadow: unset;"
              @click="checkEventDelete()"
            >
              <v-icon class="mr-1" style="padding-bottom: 3px;">mdi-delete-outline</v-icon>
              {{ $t("deletecourse") }}
            </v-btn>
            <v-hover v-slot="{ hover }">
              <v-btn
                style="font-size: 16px;color: #A6CC39;background-color: rgb(166, 204, 57,0.2);;border-radius: border-radius: 8px;box-shadow: unset;"
                class="text-capitalize"
                :style="{'color': hover ? '#ffffff' : '#AECB53',
                          'background-color': hover ? '#AECB53' : 'rgb(166, 204, 57,0.2)'}"
                @click="checkParticipantView()"
              >
                <v-icon class="mr-1" style="padding-bottom: 1px;">mdi-eye</v-icon>
                {{ $t("viewparticipant") }}
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <Edit :show="editDialog" @close="editDialog = false" />

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      :scrollable="false"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important;">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg= false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border:1px solid #FFFFFF30; "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius:8px; margin:auto;"
          :src="$store.getters.event.orgImage"
        ></v-img>
      </v-card>
    </v-dialog>

    <Delete
      :show="deleteDialog"
      :title="$t('deleteconfirm')"
      :text="$route.params.id"
      @close="deleteDialog = false"
      @confirm="deleteConfirm"
    />

    <SuccessDialog
      :show="successDialog"
      :title="$t('success')"
      :text="$t('eventdelete')"
      @close="(successDialog = false), $router.push('/home/event')"
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import Delete from "./Delete.vue";
import Edit from "./Edit.vue";
import moment from "moment";
export default {
  components: { Delete, Edit },
  computed: {
    breadcrumbs() {
      return [
        {
          // icon: "mdi-home-variant-outline",
          // image: "house.png",
          sidebar_tilte: "home"
        },
        {
          text: "event",
          href: "/home/event"
        },
        {
          text: "calendar"
        },
        {
          text: this.$store.getters.event.courseName
        }
      ];
    }
  },
  data: () => ({
    previewimg: false,
    showEditImgTool: false,
    permissionDialog: false,
    permissionMessage: "",
    deleteDialog: false,
    successDialog: false,
    editDialog: false,
    editData: {},
    courseTime: "",
    paticipantlist: "",
    remainingcount: "",
    loading: false
  }),
  mounted() {
    if (!this.$store.getters.event.courseName) {
      this.getData();
    }
    this.courseTime = moment(
      String(this.$store.getters.event.courseTime)
    ).format("hh:mm A");
  },
  methods: {
    async checkParticipantView() {
      let self = this;
      let checkPermission = true;
      if (checkPermission) {
        self.$router.push("/home/event/participant/" + self.$route.params.id);
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Participant.";
      }
    },

    async checkEventDelete() {
      let self = this;
      let checkPermission = self.check_Permissions("event");
      if (checkPermission) {
        self.deleteDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete Event.";
      }
    },
    async checkEventEdit() {
      let self = this;
      let checkPermission = self.check_Permissions("event");

      if (checkPermission) {
        self.editDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to edit Event.";
      }
    },
    async getData() {
      this.loading = true;
      const res = await this.$axios.post(
        `${this.web_urlV2}Courses/GetCourseByIDWeb`,
        {
          userID: parseInt(localStorage.getItem("UserID")),
          courseID: parseInt(this.$route.params.id)
        }
      );
      this.courseTime = moment(String(res.data.data.courseTime)).format(
        "hh:mm A"
      );
      this.remainingcount = parseInt(
        res.data.data.noOfParticipants - res.data.data.enrollCount
      );
      this.$store.commit("addEvent", res.data.data);
    
      this.$store.getters.event.noOfParticipants = parseInt(
        res.data.data.noOfParticipants
      );
      this.loading = false;
    },

    async deleteConfirm() {
      let that = this;
      await that.$axios.post(
        `${that.web_url}Courses/RemoveCourse`,
        {
          id: parseInt(that.$route.params.id)
        }
      ).then(function(res){
         if (res.data.status == 0) {
        that.deleteDialog = false;
         that.successDialog = true;
        }
      });
    }
  }
};
</script>
<style scoped>
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
::v-deep .list-item-padding.preview.v-list-item.theme--light {
  border-top: unset !important;
}
::v-deep .list-item-padding.v-list-item:hover {
  background-color: unset !important;
}
.breaktext {
  white-space: pre-line!important;
}
</style>
